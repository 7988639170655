@import '~antd/dist/antd.css';

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
KISSY CSS Reset
理念：1. reset 的目的不是清除浏览器的默认样式，这仅是部分工作。清除和重置是紧密不可分的。
2. reset 的目的不是让默认样式在所有浏览器下一致，而是减少默认样式有可能带来的问题。
3. reset 期望提供一套普适通用的基础样式。但没有银弹，推荐根据具体需求，裁剪和修改后再使用。
特色：1. 适应中文；2. 基于最新主流浏览器。
维护：玉伯<lifesinger@gmail.com>, 正淳<ragecarrier@gmail.com>
 */

/** 清除内外边距 **/
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
form,
fieldset,
legend,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td

/* table elements 表格元素 */
  {
  margin: 0;
  padding: 0;
}

/** 设置默认字体 **/
body,
button,
input,
select,
textarea

/* for ie */
  {
  font: 12px/1.5 tahoma, arial, \5b8b\4f53, sans-serif;
  /* font: 14px/1.5 "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
  font-family: courier new, courier, monospace;
}

/* 统一等宽字体 */
small {
  font-size: 12px;
}

/* 小于 12px 的中文很难阅读，让 small 正常化 */

/** 重置列表元素 **/
ul,
ol {
  list-style: none;
}

/** 重置文本格式元素 **/
a {
  text-decoration: none;
}

/*a:hover { text-decoration: underline; }*/

/** 重置表单元素 **/
legend {
  color: #000;
}

/* for ie6 */
fieldset,
img {
  border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */

/** 重置表格元素 **/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 清除浮动 */
.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.clearfix {
  *zoom: 1;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border-radius: 0;
}

@font-face {
  font-family: 'webfont';
  /* font-display: swap; */
  src: url('./font/webfont.eot');
  /* IE9 */
  src: url('./font/webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('./font/webfont.woff2') format('woff2'),
    url('./font/webfont.woff') format('woff'),
    /* chrome、firefox */
    url('./font/webfont.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url('./font/webfont.svg#webfont') format('svg');
  /* iOS 4.1- */
}

body {
  margin: 0;
  font-family: 'webfont' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt80 {
  margin-top: 80px;
}

.mr80 {
  margin-right: 80px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb17 {
  margin-bottom: 17px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb46 {
  margin-bottom: 46px;
}


/* 统一标题样式 */
.w-title {
  font-size: 40px;
  font-weight: bold;
  color: #332019;
}