.content {
  width: 375px;
  margin: 0px auto;
}
.form-detail{
  background-image: url(https://img.zgwstxc.com/524a9d71-6ddf-5e23-4212-65a629100c01.png);
  background-repeat: no-repeat;
  background-size: 100% 338px;
  padding-top: 320px;
}
.show{
  display: block;
}
.hide{
  display: none;
}
.form-box {
  padding: 25px 15px 0px;
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;

}
.title {
  color: #121212;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #EEEEEE;
}
.label {
  color: #121212;
  font-size: 14px;
}

.input {
  color: #121212;
  width: 50%;
  text-align: right;
  font-size: 12px;
  border: none;
  outline: none;
}

.areatext {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.color1212 {
  color: #121212;
}

.color999 {
  color: #999999;
}

.areaimg {
  width: 6px;
  height: 9px;
  margin-left: 10px;
}
.tips {
  color: #999999;
  font-size: 12px;
  margin-top: 15px;
}
.btn {
  border: none;
  border-radius: 50px;
  width: 100%;
  margin: 31px auto 0;
  text-align: center;
  background-color: #167FFF;
  font-size: 16px;
  font-weight: bold;
  height: 44px;
  line-height: 44px;
  color: #fff;
}
.select-agreement{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.select-icon {
  font-size: 0;
}
.select-icon img{
  width: 14px;
  height: 14px;
  border-radius: 50px;
  margin-right: 10px;
}
.select-right{
  font-size: 10px;
  color: #999999;
}
.select-right span{
  color: #121212;
}


.detail-img img{
  width: 100%;
  height: auto;
  margin-top: 15px;
}

.serve-detail-text{
  padding: 20px 15px;
}
.detail-title{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.detail-text p{
  font-size: 13px;
  color: #121212;
  line-height: 20px;
}
.color-red{
  color: #FF3434;
  font-weight: bold;
}
.color-black{
  color: #121212;
  font-weight: bold;
}
.detail-text .detail-content{
  text-indent: 20px;
}
.aling-right{
  text-align: right;
  margin-top: 20px;
}
.detail-2{
  margin-top: 30px;
}
.agreement-box{
  padding: 20px 0px;
  position: relative;
  width: 100%;
}
.agreement-box .title{
  text-align: center;
  padding: 20px 0px 15px;
  font-size: 18px;
}
.agreement-box .close{
  position: absolute;
  right: 0px;
  top: 10px;
  background-color: #167FFF;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  
}
.agreement-text{
  font-size: 14px;
  line-height: 20px;
  text-indent: 20px;
}