body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pd20 {
  padding: 0 30px;
}

.text-bold {
  font-size: 19px;
  font-weight: bold;
}

.mt10 {
  margin-top: 10px;
}

.mt3 {
  margin-top: 3px;
}

.fwred16{
  font-size: 16px;
  color: red;
  font-weight: bold;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*
KISSY CSS Reset
理念：1. reset 的目的不是清除浏览器的默认样式，这仅是部分工作。清除和重置是紧密不可分的。
2. reset 的目的不是让默认样式在所有浏览器下一致，而是减少默认样式有可能带来的问题。
3. reset 期望提供一套普适通用的基础样式。但没有银弹，推荐根据具体需求，裁剪和修改后再使用。
特色：1. 适应中文；2. 基于最新主流浏览器。
维护：玉伯<lifesinger@gmail.com>, 正淳<ragecarrier@gmail.com>
 */

/** 清除内外边距 **/
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
/* structural elements 结构元素 */
dl,
dt,
dd,
ul,
ol,
li,
/* list elements 列表元素 */
pre,
/* text formatting elements 文本格式元素 */
form,
fieldset,
legend,
button,
input,
textarea,
/* form elements 表单元素 */
th,
td

/* table elements 表格元素 */
  {
  margin: 0;
  padding: 0;
}

/** 设置默认字体 **/
body,
button,
input,
select,
textarea

/* for ie */
  {
  font: 12px/1.5 tahoma, arial, \5b8b\4f53, sans-serif;
  /* font: 14px/1.5 "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

/* 将斜体扶正 */
code,
kbd,
pre,
samp {
  font-family: courier new, courier, monospace;
}

/* 统一等宽字体 */
small {
  font-size: 12px;
}

/* 小于 12px 的中文很难阅读，让 small 正常化 */

/** 重置列表元素 **/
ul,
ol {
  list-style: none;
}

/** 重置文本格式元素 **/
a {
  text-decoration: none;
}

/*a:hover { text-decoration: underline; }*/

/** 重置表单元素 **/
legend {
  color: #000;
}

/* for ie6 */
fieldset,
img {
  border: 0;
}

/* img 搭车：让链接里的 img 无边框 */
button,
input,
select,
textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
/* 注：optgroup 无法扶正 */

/** 重置表格元素 **/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 清除浮动 */
.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.clearfix {
  *zoom: 1;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border-radius: 0;
}

@font-face {
  font-family: 'webfont';
  /* font-display: swap; */
  src: url(/static/media/webfont.7786794e.eot);
  /* IE9 */
  src: url(/static/media/webfont.7786794e.eot?#iefix) format('embedded-opentype'),
    
    url(/static/media/webfont.4b03460f.woff2) format('woff2'),
    url(/static/media/webfont.2fa4cf2a.woff) format('woff'),
    
    url(/static/media/webfont.95d9f959.ttf) format('truetype'),
    
    url(/static/media/webfont.5a66e1f7.svg#webfont) format('svg');
  /* iOS 4.1- */
}

body {
  margin: 0;
  font-family: 'webfont' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt80 {
  margin-top: 80px;
}

.mr80 {
  margin-right: 80px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb17 {
  margin-bottom: 17px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb46 {
  margin-bottom: 46px;
}


/* 统一标题样式 */
.w-title {
  font-size: 40px;
  font-weight: bold;
  color: #332019;
}
#index .wrap {
  margin-top: 64px;
  border: 1px solid #ccc;
  border-top: none;
}

#index .ant-menu {
  border-right: none;
}

#index .ant-menu-item {
  margin-right: 1px;
  background-color: rgba(0, 0, 0, .02);
  border-right: 3px solid rgba(0, 0, 0, 0);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  transition: 0;
}

#index .ant-menu-item-selected,
#index .ant-menu-item:hover {
  border-right-color: #1890ff;
  background-color: #e6f7ff;
}

#index .i-index-list {
  border-left: 1px dashed #ccc;
}
/* 头部导航 */
#w-head {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
  background-color: #fff;
  box-shadow: 0 0 43px 0 rgba(139, 139, 139, 0.59);
}

.w-head {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.wzg-head {
  height: 100%;
  width: 1216px;
  min-width: 1216px;
  margin: auto;
}

.wzg-head-scroll {
  background-color: #fff;
}

.h100 {
  height: 100%;
}

.logo-img {
  width: 100px;
  height: 44px;
}

.w-head-right {
  text-align: right;
}

.w-head-right a {
  margin-left: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #464646;
  transition: 0.2s;
}

.w-head-right a:hover,
.w-head-right-scroll a:hover {
  opacity: 0.5;
}

.w-head-right-scroll a {
  font-weight: 600;
  color: #f36d3c;
  transition: 0.2s;
}

#w-head-nav-block {
  width: 100%;
  /* min-width: 1216px; */
  height: 80px;

}

/* 手机妈妈 */
#w-head-nav {
  min-width: 1216px;
  /* height: 680px; */
  /* padding: 0 0 80px; */
  /* background: url('../../images/nav-bg.png') no-repeat;
	background-size: cover; */
}

#w-head-nav .phone-dehui {
  position: relative;
  /* width: 1216px; */
  /* height: 680px; */
  /* min-width: 1216px; */
  margin: auto;
  /* background-color: #f11; */
}

#w-head-nav .phone-dehui .img1 {
  width: 100%;
  height: 850px;
}

#w-head-nav .phone-desc {
  /* position: absolute;
	top: 237px;
	left: 170px; */
  display: flex;
  /* margin-top: 180px; */
}


.ml10 {
  margin-left: 10px;
}


/* 二维码部分 */
/* .QRCode {
  margin-top: 100px;
} */

.QRCode .QR {
  float: left;
  text-align: center;
}

.QRCode .QR .QRImg {
  /* padding: 13px; */
  /* border: 1px solid rgba(255, 255, 255, .5); */
  border-radius: 8px;
}

.QRCode .QR .QRImg img {
  width: 112px;
  height: 112px;
  border-radius: 10px;
}

.QRCode .QR .name {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 800;
  color: #fff;
}


#security-system ul {
  display: flex;
  justify-content: space-around;
  width: 1216px;
  min-width: 1216px;
  margin: 0 auto;
}

#security-system ul li {
  margin: 77px 0;
}

#security-system .img {
  width: 48px;
  height: 48px;
}

#security-system .name {
  margin-left: 16px;
  font-size: 20px;
  font-weight: bold;
  color: #332019;
  vertical-align: middle;
}

#security-system .desc {
  margin-top: 10px;
  width: 250px;
  font-size: 14px;
  color: #665853;
  /* line-height: 26px; */
}

/* 平台介绍 */
#introduction {
  width: 100%;
  /* height: 508px; */
  background-color: #f7f4f2;
}

#introduction .img2 {
  width: 100%;
  height: 380px;

}



#broken-screensaver .broken-screensaver {
  width: 100%;
  height: 956px;
}

#purchasing-by-rent .purchasing-by-rent {
  width: 100%;
  height: 869px;
}


.delivery p,
.exchange p {
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: left;
}

.delivery .desc,
.exchange .desc {
  width: 280px;
  font-size: 14px;
  color: #fff;
  text-align: left;
  line-height: 30px;
}

#contactUS {
  /* height: 373px; */
  background-color: #332019;
}

#contactUS .contactUS {
  width: 1520px;
  height: 373px;
  min-width: 1520px;
  margin: 0 auto;
  padding: 56px 0 0 137px;
  background: url(/static/media/foot-bg.64332a8f.png) no-repeat;
  background-size: 100%;
}

.contactUS p {
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.contactUS .copyright {
  margin-top: 55px;
  font-size: 14px;
  color: #fff;
}
.contactUS .copyright a{
  color: #fff;
}

/* 备案 */
.record a {
  color: #6c6c6c;
  white-space: nowrap;
  text-decoration: none;
}

.record .tb-footer-mod {
  margin-left: 10px;
  color: #9c9c9c;
  vertical-align: middle;
}
.content {
  width: 375px;
  margin: 0px auto;
}
.form-detail{
  background-image: url(https://img.zgwstxc.com/524a9d71-6ddf-5e23-4212-65a629100c01.png);
  background-repeat: no-repeat;
  background-size: 100% 338px;
  padding-top: 320px;
}
.show{
  display: block;
}
.hide{
  display: none;
}
.form-box {
  padding: 25px 15px 0px;
  background-color: #fff;
  border-radius: 15px 15px 0px 0px;

}
.title {
  color: #121212;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #EEEEEE;
}
.label {
  color: #121212;
  font-size: 14px;
}

.input {
  color: #121212;
  width: 50%;
  text-align: right;
  font-size: 12px;
  border: none;
  outline: none;
}

.areatext {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.color1212 {
  color: #121212;
}

.color999 {
  color: #999999;
}

.areaimg {
  width: 6px;
  height: 9px;
  margin-left: 10px;
}
.tips {
  color: #999999;
  font-size: 12px;
  margin-top: 15px;
}
.btn {
  border: none;
  border-radius: 50px;
  width: 100%;
  margin: 31px auto 0;
  text-align: center;
  background-color: #167FFF;
  font-size: 16px;
  font-weight: bold;
  height: 44px;
  line-height: 44px;
  color: #fff;
}
.select-agreement{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.select-icon {
  font-size: 0;
}
.select-icon img{
  width: 14px;
  height: 14px;
  border-radius: 50px;
  margin-right: 10px;
}
.select-right{
  font-size: 10px;
  color: #999999;
}
.select-right span{
  color: #121212;
}


.detail-img img{
  width: 100%;
  height: auto;
  margin-top: 15px;
}

.serve-detail-text{
  padding: 20px 15px;
}
.detail-title{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.detail-text p{
  font-size: 13px;
  color: #121212;
  line-height: 20px;
}
.color-red{
  color: #FF3434;
  font-weight: bold;
}
.color-black{
  color: #121212;
  font-weight: bold;
}
.detail-text .detail-content{
  text-indent: 20px;
}
.aling-right{
  text-align: right;
  margin-top: 20px;
}
.detail-2{
  margin-top: 30px;
}
.agreement-box{
  padding: 20px 0px;
  position: relative;
  width: 100%;
}
.agreement-box .title{
  text-align: center;
  padding: 20px 0px 15px;
  font-size: 18px;
}
.agreement-box .close{
  position: absolute;
  right: 0px;
  top: 10px;
  background-color: #167FFF;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  
}
.agreement-text{
  font-size: 14px;
  line-height: 20px;
  text-indent: 20px;
}
